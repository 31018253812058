import { LOCALES } from 'i18n'

import uiSlice from 'store/ui'

import { LocalStorage } from 'utils/helpers/localStorage'

interface LanguageAction {
  payload: LOCALES
  type: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PersistLanguage = () => (next: any) => (action: LanguageAction) => {
  if (uiSlice.actions.switchLanguage.type === action.type) {
    LocalStorage.setLanguage(action.payload)
  }

  return next(action)
}
