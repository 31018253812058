import { ReactNode } from 'react'

import './styles.scss'

import { Chat } from 'components/Chat'
import { Footer } from 'components/Footer'
import { Header } from 'components/Header'
import { Modal } from 'components/Modal'
import { Sidebar } from 'components/Sidebar'
import { useStoreSelector } from 'hooks/useStoreSelector'

interface MainLayoutProps {
  children: ReactNode
}

export const MainLayout = ({ children }: MainLayoutProps) => {
  const { isChatOpened, isSidebarOpened, isModalOpened } = useStoreSelector((state) => ({
    isChatOpened: state.ui.isChatOpened,
    isSidebarOpened: state.ui.isSidebarOpened,
    isModalOpened: state.ui.modal.isOpen,
  }))

  const isBodyOverflowed = isChatOpened || isSidebarOpened || isModalOpened

  if (isBodyOverflowed) {
    document.body.style.overflow = 'hidden'
  }
  if (!isBodyOverflowed) {
    document.body.style.overflow = 'auto'
  }

  return (
    <>
      <div className='app-content'>
        <Sidebar />
        <div className='main-content'>
          <Header />
          {children}
          <Footer />
        </div>
      </div>
      <div className='app-chat'>
        <Chat />
      </div>
      <Modal />
    </>
  )
}
