/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'

export const apiURL = 'https://sirius.circle.cloud/api/'
const instance = axios.create({
  withCredentials: true,
  baseURL: apiURL,
  headers: { 'Access-Control-Allow-Origin': '*' },
})

export const siriusAPI = {
  getOnlineAgents() {
    return instance.get(`list-online-agents`).then((res: any) => {
      return res.data
    })
  },

  updateAgentByGet(id: string, path: string) {
    return instance.get(`agents/${id}/${path}`).then((res: any) => {
      return res.data
    })
  },

  updateIps(id: string, data: any) {
    return instance.post(`agents/${id}/ipUpdate`, { ...data }).then((res: any) => {
      return res.data
    })
  },
}
