import './styles.scss'
import { InstallPbxAgentModal } from './InstallPbxAgent'
import { ProvisionPbxAgentModal } from './ProvisionPbxAgent'
import { RefreshAgentModal } from './RefreshAgent'
import { SetNewIpAgentModal } from './SetNewIpAgent'
import { UpdateAgentModal } from './UpdateAgent'

import { ModalsEnum } from 'interfaces/ui'

export const ModalComponents = {
  [ModalsEnum.refreshAgent]: RefreshAgentModal,
  [ModalsEnum.setNewIpAgent]: SetNewIpAgentModal,
  [ModalsEnum.installPbxAgent]: InstallPbxAgentModal,
  [ModalsEnum.provisionPbxAgent]: ProvisionPbxAgentModal,
  [ModalsEnum.updateAgent]: UpdateAgentModal,
}
