import { combineReducers, configureStore } from '@reduxjs/toolkit'

import { PersistLanguage } from './middleware'
import { agentsAPI } from './services/index'

import uiSlice from './ui'

const rootReducer = combineReducers({
  [uiSlice.name]: uiSlice.reducer,
  [agentsAPI.reducerPath]: agentsAPI.reducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(agentsAPI.middleware).concat(PersistLanguage),
})

export type RootState = ReturnType<typeof store.getState>

export type RootDispatch = typeof store.dispatch
