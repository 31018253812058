import { IAgentData } from './agentData'

export interface IModal {
  isOpen: boolean
  window: ModalsEnum | null
  data: TypeModalData
}

export type TypeModalData = null | IAgentData

export enum ModalsEnum {
  refreshAgent = 'RefreshAgent',
  installPbxAgent = 'InstallPbxAgent',
  provisionPbxAgent = 'ProvisionPbxAgent',
  updateAgent = 'UpdateAgent',
  setNewIpAgent = 'SetNewIpAgent',
}
