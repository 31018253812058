import SearchIcon from '@mui/icons-material/Search'
import Input from '@mui/material/Input'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

import './styles.scss'
import { t } from 'i18n/translate'

export const Error = () => {
  return (
    <div className='error-wrapper'>
      <div className='error'>
        <div className='error__inner'>
          <motion.div
            initial={{ opacity: 0, scale: 0.6 }}
            animate={{ opacity: 1, scale: 1, transition: { delay: 0.1 } }}
          >
            <Typography variant='h1' color='inherit' className='error__normal-text'>
              {t('error.404')}
            </Typography>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
          >
            <Typography variant='h5' color='textSecondary' className='error__small-text'>
              {t('error.subTitle')}
            </Typography>
          </motion.div>

          <Paper className='error__paper'>
            <SearchIcon style={{ opacity: '0.5' }} />
            <Input
              className='error__input'
              disableUnderline
              fullWidth
              inputProps={{
                'aria-label': 'Search',
              }}
            />
          </Paper>

          <Link className='font-normal' to='/'>
            {t('error.goBack')}
          </Link>
        </div>
      </div>
    </div>
  )
}
