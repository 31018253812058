import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Button } from '@mui/material'

import { useStoreDispatch } from 'hooks/useStoreDispatch'
import { useStoreSelector } from 'hooks/useStoreSelector'
import { closeChat, toggleChat } from 'store/ui'

import './styles.scss'

export const Chat = () => {
  const isChatOpened = useStoreSelector((state) => state.ui.isChatOpened)
  const dispatch = useStoreDispatch()

  const handleToggleChat = () => {
    dispatch(toggleChat())
  }

  const handleCloseChat = () => {
    dispatch(closeChat())
  }

  return (
    <div className='app-chat__inner'>
      {isChatOpened && <div className='chat-overlay' onClick={handleCloseChat} />}
      <div className={isChatOpened ? 'chat' : 'chat hidden'}>
        <div className='chat__inner'>
          <div className='chat__header'>
            <div className='chat-head'>
              <Button className='chat-button' onClick={handleToggleChat}>
                <ChatOutlinedIcon />
              </Button>
              <Button className='chat-button__close' onClick={handleToggleChat}>
                <CloseRoundedIcon />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
