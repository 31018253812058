import { List, ListItem, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import './styles.scss'
import { sidebarItems } from './data'

import logoPath from 'assets/icons/logo.svg'
import { BreakPoints } from 'constants/breakpoints'
import { useStoreDispatch } from 'hooks/useStoreDispatch'
import { useStoreSelector } from 'hooks/useStoreSelector'
import { t } from 'i18n/translate'
import { toggleSidebar } from 'store/ui'

export const Sidebar = () => {
  const isDesktop = useMediaQuery(BreakPoints.desktop)
  const isSidebarOpened = useStoreSelector((state) => state.ui.isSidebarOpened)
  const dispatch = useStoreDispatch()

  const navigate = useNavigate()

  const handleClick = () => {
    dispatch(toggleSidebar())
  }

  const handleNavigate = (to: string) => () => {
    if (!isDesktop) handleClick()
    navigate(to)
  }

  return (
    <div className='sidebar-container'>
      {isSidebarOpened && !isDesktop && <div className='sidebar-overlay' onClick={handleClick} />}
      <div
        className={
          isDesktop
            ? isSidebarOpened
              ? 'sidebar'
              : 'sidebar'
            : isSidebarOpened
            ? 'sidebar-mobile'
            : 'sidebar-mobile hidden'
        }
      >
        <div className='sidebar__head'>
          <div className='sidebar-logo'>
            <img src={logoPath} alt='logo' />
          </div>
        </div>
        <div className='sidebar__content'>
          <List>
            {sidebarItems.map((item) => (
              <ListItem
                className='sidebar-button'
                button
                key={item.label}
                onClick={handleNavigate(item.to)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={t(item.label)} />
              </ListItem>
            ))}
          </List>
        </div>
      </div>
    </div>
  )
}
