import { Avatar, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import avatarPath from 'assets/images/profile/avatar.jpg'
import { t } from 'i18n/translate'

export const ButtonProfile = () => {
  const navigate = useNavigate()

  return (
    <Button className='button-profile' color='inherit' onClick={() => navigate('/')}>
      <div className='button-profile__info'>
        <Typography component='span'>John Doe</Typography>
        <Typography color='textSecondary'>{t('header.profile.guest')}</Typography>
      </div>

      <Avatar className='button-profile__avatar' alt='user photo' src={avatarPath} />
    </Button>
  )
}
