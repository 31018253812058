import { Button } from '@mui/material'

import { siriusAPI } from 'api/api'

import { useStoreDispatch } from 'hooks/useStoreDispatch'
import { useStoreSelector } from 'hooks/useStoreSelector'
import { IAgentData } from 'interfaces/agentData'
import { closeModal } from 'store/ui'

export const InstallPbxAgentModal = () => {
  const data = useStoreSelector((state) => state.ui.modal.data)
  const dispatch = useStoreDispatch()

  const fetchGet = () => {
    const filteredData = data as IAgentData
    siriusAPI.updateAgentByGet(filteredData.agent_id, filteredData.path)
  }

  const handleClose = () => {
    dispatch(closeModal())
    fetchGet()
  }

  return (
    <div className='modal__inner'>
      <Button variant='contained' onClick={handleClose}>
        Install PBX
      </Button>
    </div>
  )
}
