import { Button, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material'
import { MouseEvent, useState } from 'react'

import USflag from 'assets/icons/en.svg'
import SAflag from 'assets/icons/ru.svg'

import { useStoreDispatch } from 'hooks/useStoreDispatch'
import { useStoreSelector } from 'hooks/useStoreSelector'
import { LOCALES } from 'i18n'
import { switchLanguage } from 'store/ui'

interface Language {
  id: LOCALES
}

const languages = [
  { id: LOCALES.EN, title: 'English', flag: USflag },
  { id: LOCALES.RU, title: 'Russian', flag: SAflag },
]

export const LanguageSwitcher = () => {
  const dispatch = useStoreDispatch()

  const currentLanguageId = useStoreSelector((state) => state.ui.language)
  const currentLanguage = languages.find((lng) => lng.id === currentLanguageId)

  const [menu, setMenu] = useState<null | HTMLButtonElement>()

  const langMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setMenu(event.currentTarget)
  }

  const langMenuClose = () => {
    setMenu(null)
  }

  function handleLanguageChange(lng: Language) {
    dispatch(switchLanguage(lng.id))

    langMenuClose()
  }

  return (
    <>
      <Button className='button-switch-language' onClick={langMenuClick}>
        <img src={currentLanguage!.flag} />

        <Typography color='textSecondary'>{currentLanguage!.id}</Typography>
      </Button>

      <Popover
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={langMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-9',
        }}
      >
        {languages.map((lng) => (
          <MenuItem
            key={lng.id}
            className='languages-list'
            onClick={() => handleLanguageChange(lng)}
          >
            <ListItemIcon>
              <img src={lng.flag} />
            </ListItemIcon>
            <ListItemText primary={lng.title} />
          </MenuItem>
        ))}
      </Popover>
    </>
  )
}
