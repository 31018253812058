import { Button, ListItemIcon } from '@mui/material'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useEffect, useState } from 'react'

import './styles.scss'
import { agentsButtons, agentsInfo, online_agents_data } from './data'

import { siriusAPI } from 'api/api'
import { useStoreDispatch } from 'hooks/useStoreDispatch'
import { ModalsEnum } from 'interfaces/ui'
// import { agentsAPI } from 'store/services'
import { openModal } from 'store/ui'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))
export const Agents = () => {
  const [agents, setAgents] = useState(online_agents_data.agents)

  const dispatch = useStoreDispatch()

  const fetchOnlineAgents = async () => {
    const res = await siriusAPI.getOnlineAgents()
    setAgents(res.agents)
  }

  useEffect(() => {
    fetchOnlineAgents()
    const int = setInterval(() => {
      fetchOnlineAgents()
    }, 5000)
    return () => {
      clearInterval(int)
    }
  }, [])

  const handleClick = (path: string, agent_id: string, payload: ModalsEnum) => () => {
    dispatch(openModal({ window: payload, data: { path, agent_id } }))
  }

  return (
    <div className='agents-wrapper'>
      <div className='agents'>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label='customized table'>
            <TableHead>
              <TableRow>
                {agentsInfo.map((info, index) => (
                  <StyledTableCell key={index}>{info.title}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {agents.map((agent) => {
                return (
                  <StyledTableRow key={agent._id}>
                    <StyledTableCell>{agent.agent_id.split('-')[0]}</StyledTableCell>
                    <StyledTableCell>{agent.state}</StyledTableCell>
                    <StyledTableCell>{agent.version}</StyledTableCell>
                    <StyledTableCell>{agent.out_ip}</StyledTableCell>
                    <StyledTableCell>{agent.in_ip}</StyledTableCell>
                    <StyledTableCell>{agent.last_online}</StyledTableCell>
                    <StyledTableCell className='agent-buttons-inner'>
                      <div className='agent-button-group'>
                        {agentsButtons.map((button, index) => (
                          <Button
                            className='agent-buttons'
                            onClick={handleClick(button.path, agent.agent_id, button.actionPayload)}
                            key={index}
                          >
                            <ListItemIcon>{button.icon}</ListItemIcon>
                          </Button>
                        ))}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}
