import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import { useMediaQuery } from '@mui/material'
import clsx from 'clsx'

import './styles.scss'
import { Chat } from './Buttons/Chat'
import { LanguageSwitcher } from './Buttons/LanguageSwitcher'
import { ButtonProfile } from './Buttons/Profile'

import { BreakPoints } from 'constants/breakpoints'
import { useStoreDispatch } from 'hooks/useStoreDispatch'
import { useStoreSelector } from 'hooks/useStoreSelector'
import { toggleSidebar } from 'store/ui'

export const Header = () => {
  const isDesktop = useMediaQuery(BreakPoints.desktop)
  const isSidebarOpened = useStoreSelector((state) => state.ui.isSidebarOpened)
  const dispatch = useStoreDispatch()

  const handleClick = () => {
    dispatch(toggleSidebar())
  }

  return (
    <header className='header'>
      <div className='header-services'>
        <button
          className={
            isDesktop
              ? clsx('sidebar-toggle', { header__sidebar_hidden: !isSidebarOpened })
              : clsx('sidebar-toggle', { header__sidebar_hidden: isSidebarOpened })
          }
          onClick={handleClick}
        >
          <MenuOpenIcon />
        </button>
      </div>
      <div className='header-features'>
        <LanguageSwitcher />
        <Chat />
        <ButtonProfile />
      </div>
    </header>
  )
}
