import { LOCALES } from 'i18n/locales'

export default {
  [LOCALES.RU]: {
    // Sidebar Elements

    'sidebar.home': 'Главная',
    'sidebar.profile': 'Профиль',
    'sidebar.login': 'Вход',
    'sidebar.404': '404',
    'sidebar.pages': 'Страницы',
    'sidebar.agents': 'Агенты',

    // Profile Elements

    'profile.follow': 'Подписаться',
    'profile.sendMessage': 'Написать',

    // Header Elements

    'header.profile.guest': 'Гость',

    // Error Page Elements

    'error.404': '404',
    'error.subTitle': 'Извините, но такой страницы не существует',
    'error.search': 'Поиск',
    'error.goBack': 'Вернуться обратно',

    // Auth Login Page Elements

    'auth.login.welcomer': 'Добро пожаловать {code} в Circle Cloud!',
    'auth.login.subTitle':
      'Мощный и профессиональный шаблон администратора для веб-приложений, CRM, CMS, панелей администратора и многого другого.',
    'auth.login.login': 'Логин',
    'auth.login.password': 'Пароль',
    'auth.login.loginButton': 'Войти',
    'auth.login.backToDashboard': 'Вернуться обратно',
  },
}
