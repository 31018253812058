import { LOCALES } from 'i18n/locales'

export default {
  [LOCALES.EN]: {
    // Sidebar Elements

    'sidebar.home': 'Home',
    'sidebar.profile': 'Profile',
    'sidebar.login': 'Login',
    'sidebar.404': '404',
    'sidebar.pages': 'Pages',
    'sidebar.agents': 'Agents',

    // Profile Page Elements

    'profile.follow': 'Follow',
    'profile.sendMessage': 'Send Message',

    // Header Elements

    'header.profile.guest': 'Guest',

    // Error Page Elements

    'error.404': '404',
    'error.subTitle': 'Sorry but we could not find the page you are looking for',
    'error.search': 'Search for anything',
    'error.goBack': 'Go back to dashboard',

    // Auth Login Page Elements

    'auth.login.welcomer': 'Welcome {code} to the Circle Cloud!',
    'auth.login.subTitle':
      'Powerful and professional admin template for Web Applications, CRM, CMS, Admin Panels and more.',
    'auth.login.login': 'Login',
    'auth.login.password': 'Password',
    'auth.login.loginButton': 'Login',
    'auth.login.backToDashboard': 'Back to Dashboard',
  },
}
