import { Route, Routes } from 'react-router-dom'

import { ROUTES } from 'constants/routes'
import { MainLayout } from 'layouts/Main'
import { Error } from 'pages/404'
import { Agents } from 'pages/Agents'

export const Routing = () => {
  return (
    <Routes>
      <Route
        path={ROUTES.HOME}
        element={
          <MainLayout>
            <Agents />
          </MainLayout>
        }
      />
      <Route
        path={ROUTES.ALL}
        element={
          <MainLayout>
            <Error />
          </MainLayout>
        }
      />
    </Routes>
  )
}
