import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { LOCALES } from 'i18n'
import { TypeModalData, IModal, ModalsEnum } from 'interfaces/ui'
import { LocalStorage } from 'utils/helpers/localStorage'

interface UI {
  language: LOCALES
  isSidebarOpened: boolean
  isChatOpened: boolean
  modal: IModal
}

const initialState: UI = {
  language: LocalStorage.getLanguage() || LOCALES.EN,
  isSidebarOpened: false,
  isChatOpened: false,
  modal: {
    isOpen: false,
    window: null,
    data: null,
  },
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    switchLanguage: (state, action: PayloadAction<LOCALES>) => {
      state.language = action.payload
    },
    toggleSidebar: (state) => {
      state.isSidebarOpened = !state.isSidebarOpened
    },
    toggleChat: (state) => {
      state.isChatOpened = !state.isChatOpened
    },
    closeChat: (state) => {
      state.isChatOpened = false
    },
    openModal: (state, action: PayloadAction<{ window: ModalsEnum; data: TypeModalData }>) => {
      state.modal.isOpen = true
      state.modal.data = action.payload.data
      state.modal.window = action.payload.window
    },
    closeModal: (state) => {
      state.modal.isOpen = false
      state.modal.window = null
    },
  },
})

export const { switchLanguage, toggleSidebar, toggleChat, closeChat, openModal, closeModal } =
  uiSlice.actions

export default uiSlice
