import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { IAgent } from 'interfaces/agent'

const baseQuery = fetchBaseQuery({
  baseUrl: `https://sirius.circle.cloud/api`,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
})

interface IFetchAgent {
  id: string
  path: string
}

interface IFetchNewIp {
  id: string
}

export const agentsAPI = createApi({
  reducerPath: 'profileAPI',
  baseQuery,
  endpoints: (build) => ({
    fetchAgentsList: build.query<IAgent, void>({
      query: () => ({
        url: `/list-online-agents`,
      }),
    }),
    fetchAgent: build.query<IAgent, IFetchAgent>({
      query: ({ id, path }: IFetchAgent) => ({
        url: `agents/${id}/${path}`,
      }),
    }),
    fetchNewIp: build.mutation<IAgent, IFetchNewIp>({
      query: ({ id }: IFetchNewIp) => ({
        url: `agents/${id}/ipUpdate`,
        method: 'POST',
      }),
    }),
  }),
})
