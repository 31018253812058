import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline'
import { ReactNode } from 'react'

interface SidebarItem {
  label: string
  to: string
  icon: ReactNode
}

export const sidebarItems: SidebarItem[] = [
  { label: 'sidebar.agents', to: '/', icon: <PeopleOutlineIcon fontSize='large' /> },
]
