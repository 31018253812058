import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined'
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined'
import { ReactNode } from 'react'

import { ModalsEnum } from 'interfaces/ui'

interface AgentsButtons {
  title: string
  icon: ReactNode
  actionPayload: ModalsEnum
  path: string
}

interface AgentsInfo {
  title: string
}

export const online_agents_data = {
  count: 7,
  agents: [
    {
      _id: '624ac643369a9b6068e77d40',
      agent_id: 'c06f92e0-b400-11ec-9026-57fc484ece29',
      version: '1.1.1',
      last_online: '2022-05-01T21:08:45.148Z',
      last_ip: '213.217.8.65',
      state: 'pbx_unprov',
      out_ip: '213.217.8.65',
      in_ip: '192.168.213.221',
      uptime: 303978.72,
    },
    {
      _id: '624ada3f369a9b6068e784a5',
      agent_id: 'a3b8b080-b40c-11ec-a50d-bd1a7542cc17',
      version: '1.1.1',
      last_online: '2022-05-01T21:08:48.860Z',
      last_ip: '176.35.215.17',
      state: 'pbx_unprov',
      out_ip: '176.35.215.17',
      in_ip: '192.168.1.222',
      uptime: 556932.9,
    },
    {
      _id: '624ae0c5369a9b6068e78684',
      agent_id: '8dbd90d0-b410-11ec-9b50-dbf215280cbd',
      version: '1.1.1',
      last_online: '2022-05-01T21:08:36.609Z',
      last_ip: '109.151.81.208',
      state: 'sync_unprov',
      out_ip: '109.151.81.208',
      in_ip: '192.168.1.153',
      uptime: 952943.57,
    },
    {
      _id: '624ae35a369a9b6068e78745',
      agent_id: '14e47410-b412-11ec-94a4-01a8760a7ca4',
      version: '1.1.1',
      last_online: '2022-05-01T21:08:42.084Z',
      last_ip: '91.85.222.129',
      state: 'pbx_unprov',
      out_ip: '91.85.222.129',
      in_ip: '192.168.1.110',
      uptime: 831271.34,
    },
    {
      _id: '624af898369a9b6068e78f0b',
      agent_id: 'baae5ad0-b41e-11ec-8070-8b195db69cb0',
      version: '1.1.1',
      last_online: '2022-05-01T21:08:24.525Z',
      last_ip: '51.52.105.206',
      state: 'pbx_unprov',
      out_ip: '51.52.105.206',
      in_ip: '192.168.1.88',
      uptime: 433388.83,
    },
    {
      _id: '62584df3c8cf3097b0885c61',
      agent_id: '38d23950-bc11-11ec-925d-0f9a454cac87',
      version: '1.1.1',
      last_online: '2022-05-01T21:08:28.813Z',
      last_ip: '157.231.56.51',
      state: 'pbx_unprov',
      out_ip: '157.231.56.51',
      in_ip: '192.168.1.20',
      uptime: 218667.65,
    },
    {
      _id: '6266b0613eeea166379d750c',
      agent_id: '25858230-c4a4-11ec-a92e-2d4e18736a9c',
      version: '1.1.1',
      last_online: '2022-05-01T21:08:43.414Z',
      last_ip: '213.217.8.65',
      state: 'sync_unprov',
      out_ip: '213.217.8.65',
      in_ip: '192.168.213.181',
      uptime: 542355.29,
    },
  ],
}

export const agentsButtons: AgentsButtons[] = [
  {
    title: 'Reboot',
    icon: <ReplayOutlinedIcon fontSize='medium' />,
    actionPayload: ModalsEnum.refreshAgent,
    path: 'reboot',
  },
  {
    title: 'Install PBX',
    icon: <FileDownloadOutlinedIcon fontSize='medium' />,
    actionPayload: ModalsEnum.installPbxAgent,
    path: 'installPbx',
  },
  {
    title: 'Provision PBX',
    icon: <SecurityOutlinedIcon fontSize='medium' />,
    actionPayload: ModalsEnum.provisionPbxAgent,
    path: 'provisionPbx',
  },
  {
    title: 'Update Agent',
    icon: <UpdateOutlinedIcon fontSize='medium' />,
    actionPayload: ModalsEnum.updateAgent,
    path: 'updateAgent',
  },
  {
    title: 'Set Ip',
    icon: <SettingsOutlinedIcon fontSize='medium' />,
    actionPayload: ModalsEnum.setNewIpAgent,
    path: 'setIp',
  },
]

export const agentsInfo: AgentsInfo[] = [
  { title: 'ID' },
  { title: 'STATE' },
  { title: 'VERSION' },
  { title: 'OUT IP' },
  { title: 'IN IP' },
  { title: 'LAST ONLINE' },
  { title: 'CONTROL' },
]
