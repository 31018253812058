/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, TextField } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { siriusAPI } from 'api/api'
import { useStoreDispatch } from 'hooks/useStoreDispatch'
import { useStoreSelector } from 'hooks/useStoreSelector'
import { IAgentData } from 'interfaces/agentData'
import { closeModal } from 'store/ui'

const validationSchema = yup.object({
  address: yup
    .string()
    .matches(/^([0-9]{1,3}\.){3}[0-9]{1,3}($|\/(16|24))$/, 'Enter a valid Cidr')
    .required('Cidr is required'),
  gw: yup
    .string()
    .matches(/^([0-9]{1,3}\.){3}[0-9]{1,3}/, 'Enter a valid Gateway')
    .required('Gateway is required'),
})

export const SetNewIpAgentModal = () => {
  const newData = useStoreSelector((state) => state.ui.modal.data)

  const dispatch = useStoreDispatch()

  const handleClose = () => {
    dispatch(closeModal())
  }

  const onIpFormSubmit = (data: any) => {
    const newModernData = newData as IAgentData
    if (data.address !== '' && data.gw !== '') {
      //  if one of fields is empty - just close window
      siriusAPI.updateIps(newModernData.agent_id, data)
    }
    console.log(data)
  }

  const formik = useFormik({
    initialValues: { address: '', gw: '' },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      onIpFormSubmit(values)
      handleClose()
    },
  })

  return (
    <div className='modal__inner_ip'>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          error={formik.touched.address && Boolean(formik.errors.address)}
          helperText={formik.touched.address && formik.errors.address}
          label='Cidr'
          id='address'
          name='address'
          type='text'
          autoComplete='off'
          onChange={formik.handleChange}
          value={formik.values.address}
        />
        <TextField
          error={formik.touched.gw && Boolean(formik.errors.gw)}
          helperText={formik.touched.gw && formik.errors.gw}
          label='Gateway'
          id='gw'
          name='gw'
          type='text'
          autoComplete='off'
          onChange={formik.handleChange}
          value={formik.values.gw}
        />
        <Button variant='contained' type='submit'>
          Save
        </Button>
      </form>
    </div>
  )
}
