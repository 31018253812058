import { ReactNode } from 'react'
import { IntlProvider } from 'react-intl'

import { LOCALES } from './locales'

import languages from 'i18n/languages'

interface ProviderProps {
  children: ReactNode
  locale: LOCALES
}

const I18nProvider = ({ children, locale = LOCALES.EN }: ProviderProps) => {
  return (
    <IntlProvider locale={locale} messages={languages[locale]}>
      {children}
    </IntlProvider>
  )
}

export default I18nProvider
