import './styles/app.scss'
import { useStoreSelector } from 'hooks/useStoreSelector'
import { I18nProvider } from 'i18n'
import { Routing } from 'routing'

function App() {
  const currentLanguageId = useStoreSelector((state) => state.ui.language)

  return (
    <I18nProvider locale={currentLanguageId}>
      <div className='app'>
        <Routing />
      </div>
    </I18nProvider>
  )
}

export default App
