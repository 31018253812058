import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import { Button } from '@mui/material'

import { useStoreDispatch } from 'hooks/useStoreDispatch'
import { toggleChat } from 'store/ui'

export const Chat = () => {
  const dispatch = useStoreDispatch()

  const handleToggleChat = () => {
    dispatch(toggleChat())
  }

  return (
    <Button className='button-toggle-chat' onClick={handleToggleChat}>
      <ChatOutlinedIcon />
    </Button>
  )
}
